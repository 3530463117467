<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-card uk-card-default uk-margin">
      <div class="uk-modal-body">
        <label class="uk-form-label">Title</label>
          <div class="uk-width-1-1 uk-inline">
              <input 
                  class="uk-input"
                  type="text" 
                  v-model="category_detail.title" 
                  placeholder="input title">
          </div>
          <label class="uk-form-label">Icon CSS</label>
          <div class="uk-width-1-1 uk-inline">
              <input
                  class="uk-input"
                  type="text"
                  v-model="category_detail.icon_css_class"
                  placeholder="input css class"
              >
          </div>
          <label class="uk-form-label">Meta Title</label>
          <div class="uk-width-1-1 uk-inline">
              <input
                  class="uk-input"
                  type="text"
                  v-model="category_detail.meta_title"
                  placeholder="input meta title"
              >
          </div>
          <label class="uk-form-label">Meta Description</label>
          <div class="uk-width-1-1 uk-inline">
              <input
                  class="uk-input"
                  type="text"
                  v-model="category_detail.meta_desc"
                  placeholder="input meta desc"
              >
          </div>
          <label class="uk-form-label">Meta Keywords</label>
          <div class="uk-width-1-1 uk-inline">
              <input
                  class="uk-input"
                  type="text"
                  v-model="category_detail.meta_keywords"
                  placeholder="input meta keyword"
              >
          </div>
          <label class="uk-form-label">Seo Url</label>
          <div class="uk-width-1-1 uk-inline">
              <input
                  class="uk-input"
                  type="text"
                  v-model="category_detail.seo_url"
                  placeholder="input seo url"
              >
          </div>
      </div>
      <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-default" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-default" type="button" @click="saveEditModal">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            isLoading: false,
            category_detail: {
                title: '',
                icon_css_class: '',
                meta_title: '',
                meta_desc: '',
                meta_keywords: '',
                seo_url: ''
            }
        };
    },
    methods: {
        ...mapActions({
            createCategory: 'hospitality/createCategory'
        }),
        async saveEditModal() {
            this.isLoading = true;
            try {
                const response = await this.createCategory(this.category_detail);
                if (response.status === 'OK') {
                    this.isLoading = false;
                    this.$router.push('/admin/hospitality/category');
                }
            } catch (err) {
                this.isLoading = false;
                console.error(err);
            }
        }
    }
};
</script>
